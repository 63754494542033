.button-bar {
  @apply border-b px-2 py-1 flex items-center w-full;
}

.az-header {
  background-color: var(--color-primary);
  color: var(--color-txt-on-primary);
  padding: 0 var(--space-M);
  height: var(--space-3XL);


  .az-header-link {
    @apply px-3 py-1 inline-flex items-center;
    gap: var(--space-2XS);
    border-radius: var(--radius-M);
    color: var(--color-txt-on-primary);
    height: var(--space-XL);

    svg, az-icon {
      color: var(--color-txt-on-primary);
    }

    &.active, &:hover {
      background: var(--color-primary-dark);
      color: var(--color-txt-on-primary);
    }
  }

}

.dialog-footer {
  @apply flex justify-end p-2;
}

.page-header {
  @apply inline-flex items-center gap-M padding-left-XS padding-right-XS;
}

