.p-filter {
  @apply bg-gray-50 h-8 rounded text-gray-900;
}

/* Angular forms */
.ng-invalid {
  & .p-inputtext {
    @apply border-danger-500;
  }
}

/* p-table */
.p-table tbody > tr > td {
  @apply max-w-md truncate overflow-hidden whitespace-nowrap text-left border-r border-b;
}

.p-table {
  @apply rounded border bg-white shadow-sm;
  .p-table-caption.p-widget-header {
    @apply bg-gray-50 border-b border-t p-0;
  }

  thead {
    @apply rounded-t;
    th {
      @apply font-medium text-gray-500 text-left outline-none;
    }
  }

  tbody > tr > td {
    @apply px-2;
  }
}

.p-table .p-table-wrapper {
  @apply overflow-y-auto overflow-x-auto relative h-full rounded;
}

.p-table thead > tr > th,
.p-table .p-table-thead > tr > th {
  @apply text-gray-600 bg-white text-sm font-normal border-b-0 border-r border-collapse p-2;

  &:last-child {
    @apply border-r-0;
  }
}

.p-table tbody > tr > td,
.p-table .p-table-tbody > tr > td {
  @apply text-gray-800 border-t border-r py-2;

  &:last-child {
    @apply border-r-0;
  }
}

.p-table.sm {
  tbody > tr > td,
  .p-table-tbody > tr > td {
    @apply py-1;
  }

  thead > tr > th,
  .p-table-thead > tr > th {
    @apply py-1;
  }
}

.p-table.xs {
  tbody > tr > td,
  .p-table-tbody > tr > td {
    @apply py-1 text-xs;
  }

  thead > tr > th,
  .p-table-thead > tr > th {
    @apply py-1 text-sm;
  }
}

// Deprecated
.p-table.stripped tbody {
  & > tr:nth-child(even) {
    @apply bg-gray-50;
    &:focus {
      @apply bg-blue-100 outline-none;
    }
  }

  > tr:focus {
    @apply bg-blue-100 outline-none;
  }
}

.p-table.striped tbody {
  & > tr:nth-child(even) {
    @apply bg-gray-50;
    &:focus {
      @apply bg-blue-100 outline-none;
    }
  }

  > tr:focus {
    @apply bg-blue-100 outline-none;
  }
}

.p-table.hoverable tbody > tr:hover {
  background-color: #73afea0a !important;
  @apply cursor-pointer;
}

.p-table.borderless {
  @apply border-none bg-transparent shadow-none;
  .p-table-wrapper {
    @apply rounded-none;
  }

  tbody > tr > td,
  .p-table-tbody > tr > td {
    @apply border-0 border-t;
  }
}

.p-table.simple thead > tr > th {
  @apply uppercase font-semibold text-gray-600 text-sm bg-transparent border-t py-2;
}

.p-table.simple thead {
  @apply border-t;
}

.p-table tbody > tr.p-state-highlight {
  @apply bg-blue-100;
}

.p-table tr {
  @apply px-2;
}

/* p-inputtext */

.p-inputtext {
  @apply px-2;
  &:disabled {
    @apply bg-gray-100 text-gray-300;
  }

  &:hover:disabled {
    @apply border-border;
  }

  &:focus-within {
    @apply border-primary;
  }

  &:hover {
    @apply border-gray-400;
  }

  &.ng-invalid {
    @apply border-danger-500;
  }
}

/* p-autocomplete */

.p-autocomplete-panel {
  @apply border-none;
}

.p-autocomplete {
  @apply outline-none text-sm leading-5 rounded border h-9 shadow-sm flex w-full;
  .p-autocomplete-multiple-container {
    @apply inline-block flex-1 text-left bg-white rounded h-full;
  }

  .p-autocomplete-panel .p-autocomplete-list-item {
    @apply p-1;
  }

  .p-autocomplete-dropdown {
    @apply flex-shrink-0 self-center bg-white rounded-r h-full;
  }

  .p-autocomplete-token {
    @apply p-0 m-1;
  }

  .p-autocomplete-input {
    @apply h-full;
  }

  .p-autocomplete-input-token {
    @apply w-full pl-1 h-full;
    input {
      min-height: 1.75rem;
      @apply w-full h-full;
    }
  }

  .p-autocomplete-token {
    @apply px-1 py-px bg-primary text-white rounded;
    span {
      @apply text-white;
    }
  }

  .p-autocomplete-token-icon:before {
    content: "\e94e";
  }
}

.p-autocomplete-panel {
  @apply rounded bg-white shadow-lg  border;
}

/* p-checkbox */
p-checkbox {
  @apply flex items-center my-1;
}

.p-chkbox {
  .p-chkbox-box {
    @apply flex items-center justify-center border rounded h-4 w-4;
    &.p-state-active {
      @apply text-white bg-primary border-none;
      .p-chkbox-icon::before {
        @apply text-white;
      }
    }
  }

  &.p-chkbox-readonly {
    .p-chkbox-box {
      @apply flex items-center justify-center border rounded h-4 w-4;
      &.p-state-active {
        @apply text-white bg-gray-500 border-none;
        .p-chkbox-icon::before {
          @apply text-white;
        }
      }
    }
  }
}

.p-chkbox-label {
  @apply pl-2 text-sm truncate;
}

/* p-multiselect */

.p-multiselect {
  @apply flex items-center outline-none text-sm leading-5 rounded border px-3 h-9 shadow-sm;
}

.p-multiselect-panel {
  @apply rounded bg-white shadow-lg overflow-hidden border;
  .p-multiselect-item {
    display: flex !important;
    margin-top: 0 !important;
    @apply px-2 text-sm rounded flex items-center outline-none;
    &:hover {
      @apply text-primary font-semibold bg-gray-50;
    }
  }
}

.p-multiselect-label-container {
  @apply w-full;
}

.p-multiselect-items-wrapper {
  @apply border-t;
}

.p-multiselect-filter-container {
  @apply text-base px-2 flex items-center;
  input {
    @apply bg-transparent;
  }

  .p-multiselect-filter-icon {
    @apply mt-2 absolute left-0 ml-2;
  }
}

/** p-toast */
.p-toast {
  @apply w-auto max-w-md;
}

.p-toast {
  position: fixed;
  width: 20em;
}

.p-toast .p-toast-message {
  position: relative;
  display: block;
  margin-bottom: 0.75em;
  overflow: hidden;
  @apply rounded shadow-lg border bg-white;
}

.p-toast .p-toast-message-content {
  padding: 0.5em 1em;
  @apply flex flex-col p-4 items-start justify-center break-words;
}

.p-toast .p-toast-close-icon {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  cursor: pointer;
  display: inline-block;
}

.p-toast .p-toast-close-icon:before {
  font-family: "azicons";
  content: "\e94e";
}

.p-toast .p-toast-summary {
  font-weight: bold;
  display: block;
  @apply text-black leading-normal whitespace-pre-wrap;
}

.p-toast .p-toast-detail {
  @apply leading-normal whitespace-pre-wrap;
}

.p-toast .p-toast-icon {
  position: absolute;
  display: inline-block;
  left: 0.5em;
  padding: 0;
  font-size: 2em;
  @apply inset-y-0;
}

.p-toast .p-toast-icon.pi-check:before {
  font-family: "azicons";
  content: "\e94a";
  @apply text-success-500;
}

.p-toast .p-toast-icon.pi-times:before {
  font-family: "azicons";
  content: "\e906";
  @apply text-danger-500;
}

.p-toast .p-toast-icon.pi-info-circle:before {
  font-family: "azicons";
  content: "\e9a5";
  @apply text-primary;
}

.p-toast .p-toast-message-text-content {
  padding: 0 0 0.25em 0;
  margin-left: 2.5em;
  @apply leading-loose text-gray-700 leading-normal truncate;
}

/* Positions */
.p-toast-top-right {
  top: 20px;
  right: 20px;
}

.p-toast-top-left {
  top: 20px;
  left: 20px;
}

.p-toast-bottom-right {
  bottom: 20px;
  right: 20px;
}

.p-toast-bottom-left {
  bottom: 20px;
  left: 20px;
}

.p-toast-top-center {
  top: 20px;
  left: 50%;
  margin-left: -10em;
}

.p-toast-bottom-center {
  bottom: 20px;
  left: 50%;
  margin-left: -10em;
}

.p-toast-center {
  left: 50%;
  top: 50%;
  min-width: 20vw;
  transform: translate(-50%, -50%);
}

/** p-dialog */
.p-dialog {
  @apply rounded-lg shadow-xl border z-50;

  &.dark {
    @apply bg-gray-800;
    .p-dialog-title {
      @apply text-white text-lg;
    }
  }

  .p-dialog-footer {
    @apply border-none;
  }
}

.p-dialog-mask {
  @apply hidden;
}

/** p-dropdown */

.p-dropdown {
  @apply flex items-center outline-none text-sm leading-5 rounded border h-8 border border-gray-300 shadow-sm bg-white;
}

.p-dropdown-trigger {
  &.p-corner-right {
    @apply mr-2;
  }
}

.p-dropdown-trigger-icon {
  line-height: 1rem;
}

.p-dropdown-panel {
  @apply rounded bg-white shadow-lg overflow-hidden border;
  .p-dropdown-item {
    @apply p-2 text-sm rounded;
    &:hover {
      @apply text-primary font-semibold bg-gray-50;
    }
  }

  .p-dropdown-label-container {
    @apply w-full;
  }

  .p-dropdown-label {
    @apply truncate h-7 px-2 text-left;
  }

  .p-dropdown-items-wrapper {
    @apply border-t;
  }

  .p-dropdown-filter {
    @apply text-base px-2 flex items-center;
  }
}

.p-dropdown.p-state-disabled {
  @apply bg-gray-100 shadow-none cursor-not-allowed;
}

.ng-invalid.p-dropdown {
  @apply border-danger-500;
}

/* p-inputgroup */
.p-inputgroup {
  @apply flex flex-col my-1 px-2;
  input {
    @apply border rounded px-2 outline-none;
    &:focus-within {
      @apply outline-none shadow-outline;
    }
  }

  > .label {
    @apply text-gray-600 text-sm mb-1;
  }
}

/* p-inputswitch */
.p-inputswitch {
  @apply bg-gray-50 border h-5 rounded-full shadow-inner w-10;

  &.p-inputswitch-checked {
    @apply bg-primary border-primary;
  }

  .p-inputswitch-slider:before {
    position: absolute;
    content: "";
    height: 1em;
    width: 1em;
    right: 0.05em;
    bottom: 0.075em;
    border-radius: 50%;
    background-color: white;
    transition: 0.3s;
  }
}

/* p-overlaypanel */
.p-overlaypanel {
  @apply bg-white border rounded shadow;
  .p-overlaypanel-content {
    @apply p-0;
  }
}

/* p-splitbutton */
.p-splitbutton {
  @apply h-8 border rounded;
  .p-button {
    @apply h-full;
  }

  .p-button-text-icon-left {
    @apply border-r;
    &:hover {
      @apply bg-gray-100;
    }

    &:focus {
      @apply outline-none;
    }

    .p-button-text {
      @apply px-3 pl-6;
    }
  }
}

.p-splitbutton-menubutton {
  &:hover {
    @apply bg-gray-100;
  }

  &:focus {
    @apply outline-none;
  }
}

.p-button-icon-left {
  margin-top: -0.55rem;
}

/* p-listbox */
.p-listbox {
  @apply p-0 w-full;

  .p-listbox-header {
    @apply border-b;
  }

  .p-listbox-filter-container {
    @apply text-base px-2 flex items-center;
    input {
      @apply bg-transparent;
    }

    .p-listbox-filter-icon {
      @apply mt-2 absolute left-0 ml-2;
    }
  }

  .p-listbox-item {
    @apply p-2 text-sm rounded flex items-center;
    &:hover {
      @apply text-primary font-semibold bg-gray-50;
    }
  }

  .p-listbox-close-icon {
    @apply top-0 left-0;
  }

  .p-listbox-list-wrapper {
    @apply overflow-y-auto h-64;
  }
}

/* p-menu */
.p-menu {
  @apply rounded bg-white shadow-lg overflow-hidden border;
}

.p-menuitem {
  @apply p-1;
  &:hover {
    @apply bg-gray-50 rounded;
  }

  .p-menuitem-link {
    @apply text-gray-900 text-sm;
  }

  &:hover .p-menuitem-link {
    @apply text-primary font-semibold;
  }
}

.p-submenu-list {
  @apply bg-white shadow-lg rounded;
}

/* p-spinner */
.p-spinner {
  @apply h-8 border rounded flex items-center text-base;
  .p-spinner-input {
  }
}

.p-spinner .p-spinner-button-icon {
  line-height: 1rem;
}

.p-spinner-up {
  @apply border-l border-b pr-9;
  &:hover {
    @apply bg-gray-100 rounded-tr;
  }

  &:active {
    @apply bg-gray-200 rounded-tr;
  }
}

.p-spinner-down {
  @apply border-l border-t pr-9;
  &:hover {
    @apply bg-gray-100 rounded-br;
  }

  &:active {
    @apply bg-gray-200 rounded-br;
  }
}

/* p-tieredmenu */

.p-tieredmenu {
  @apply rounded bg-white shadow-lg border;

  .p-menuitem {
    @apply p-1;
    &:hover {
      @apply bg-gray-50 rounded;
    }

    .p-menuitem-link {
      @apply text-gray-900 text-sm truncate;
      .p-menuitem-text {
        @apply truncate;
      }
    }

    &:hover > .p-menuitem-link {
      @apply text-primary font-semibold;
    }
  }
}

/* p-messages */
.p-messages {
  @apply rounded border-none bg-gray-200 flex p-2;
  & > ul > li {
    @apply flex flex-col;
  }

  &.p-messages-error {
    @apply bg-danger-200;
    .p-messages-detail {
      @apply text-danger-600;
    }

    .p-messages-summary {
      @apply text-danger-700;
    }

    .p-messages-icon {
      @apply text-danger-700;
    }
  }

  &.p-messages-success {
    @apply bg-success-200;
    .p-messages-detail {
      @apply text-success-600;
    }

    .p-messages-summary {
      @apply text-success-700;
    }

    .p-messages-icon {
      @apply text-success-700;
    }
  }
}

/* p-selectbutton */
.p-selectbutton {
  .p-button {
    @apply p-1 border-t border-b text-sm;
  }

  .p-button.p-state-active {
    @apply bg-primary font-medium;
    span {
      @apply text-white;
    }
  }

  .p-button.p-state-disabled {
    @apply bg-gray-300 opacity-50;
    &:hover {
      @apply cursor-not-allowed;
    }
  }

  .p-button:first-child {
    @apply rounded-l border-l;
  }

  .p-button:last-child {
    @apply rounded-r border-r;
  }
}

/* Paginator */

.p-paginator {
  @apply w-full flex-1 px-0 py-2;
  &.p-widget-header {
    @apply px-0;
  }
  .p-paginator-element {
    @apply text-gray-600 shadow-sm bg-white border-t border-b border-r border-gray-300 duration-150 ease-in-out font-medium h-8 inline-flex items-center leading-5 px-2 px-4 py-2 relative text-sm transition mx-0 border-gray-400;
    &:active {
      @apply bg-gray-100 text-gray-500;
    }

    &:focus {
      @apply border-blue-300 outline-none shadow-outline z-10;
    }

    &:hover {
      @apply text-gray-400;
    }

    &.p-paginator-last {
      @apply rounded-r border-r;
    }

    &.p-paginator-first {
      @apply rounded-l border-l;
    }

    &.p-state-disabled {
      @apply cursor-not-allowed bg-gray-50 text-gray-400;

      &:hover {
        @apply appearance-none shadow-none;
      }

      &:focus {
        @apply appearance-none shadow-none border-gray-400;
      }
    }

    &.p-state-active {
      @apply bg-blue-100 text-primary font-bold;
    }
  }

  .p-paginator-icon {
    @apply flex items-center;
  }

  .p-paginator-pages {
    @apply h-auto mx-0;
  }
  .p-dropdown {
    @apply h-8;
  }
}

// TABS
.p-tabview {
  &.p-tabview-top > .p-tabview-nav li {
    @apply border-b-2 border-transparent;

    &.p-status-active {
      @apply border-primary;

      > a {
        @apply font-semibold text-normal;
      }
    }
  }
}

// dialog
.p-dialog {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px 10px;
  @apply border-0 rounded-none;
  .p-dialog-content {
  }

  .p-dialog-titlebar {
    @apply justify-end;
  }
}

.p-dialog-mask {
  @apply bg-black opacity-25;
}

/* togglebutton */
.p-togglebutton {
  @apply flex h-8 items-center justify-center border rounded;
  &.p-state-active {
    @apply border-0 bg-primary;
    span {
      @apply text-white;
    }
  }
}

// EXTRA
.p-widget-header {
  @apply flex px-2 py-1 items-center;
}

.p-table .p-paginator-bottom {
  @apply flex justify-end;
  border-top: 1px solid #e2e8f0;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
