.az-label-uppercase {
  @apply inline-block color-text-primary text-xs uppercase font-semibold mb-1 tracking-wide;
}

.az-form-section {
  @apply mb-8;
}

.note {
  @apply text-xs mt-2;
  min-height: 17px;
  font-size: 12px;
  color: var(--color-text-secondary);
}

.az-modal-mask {
  @apply bg-smoke-dark fixed flex h-full items-start justify-center left-0 overflow-x-hidden overflow-y-auto top-0 w-full z-40;
  background-color: #0000004d !important;

  .az-modal {
    max-height: none !important;
    @apply my-10;
  }
}

.az-modal {
  @apply flex flex-col color-bg-overlay;
  & > .az-modal-content {
    @apply flex-1 bg-transparent;
  }
}

.modal {
  & > .modal-content {
    @apply p-4 text-left;
  }

  & .modal-footer {
    @apply p-4 w-full flex justify-end;
    > button {
      @apply ml-2;
    }
  }
}
