.month-container{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.calendar {
  @apply text-neutral-700 font-sans;
}

.calendar table.month th.day-header {
  font-size: 12px;
  border-bottom-width: 2px;
  padding-bottom: 2px;
}
