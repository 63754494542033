@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@azigrene/components/style.scss";
@import "@azigrene/components/styles-ui.scss";
@import "@azigrene/data-manager/style.scss";

@import "styles/utilities.scss";
@import "styles/primeng.scss";
// @import 'styles/ngzorro.scss';
@import "styles/components.scss";
@import "styles/year-calendar.scss";

@import "tippy.js/dist/tippy.css";
//@import "ngx-drag-to-select/scss/ngx-drag-to-select.scss";

//@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

:root {
  /* Colores en verde */
  --color-primary: #097152; /* Verde primario */
  --color-primary-light: #DFF5EA; /* Verde claro */
  --color-primary-dark: #0D5B40; /* Verde oscuro */
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Colores en verde para esquema oscuro */
    --color-primary: #52B788; /* Verde primario */
    --color-primary-light: #144A3A; /* Verde oscuro */
    --color-primary-dark: #3C8D6C; /* Verde intermedio oscuro */
  }
}



// HC especificos


// ---

.dashboard-grid-item .ag-charts-wrapper {
  height: 100%;
  width: 100%;
}
.dashboard-grid-item .ag-charts-canvas-container {
  height: 100%;
  width: 100%;
}


.advanced-pie-legend .total-value {
  font-size: 24px !important;
}

.advanced-pie-legend
  .legend-items-container
  .legend-items
  .legend-item
  .item-percent {
  font-size: 20px !important;
}

.advanced-pie-legend
  .legend-items-container
  .legend-items
  .legend-item
  .item-value {
  margin-top: -1px !important;
  font-size: 16px !important;
}

html {
  font-size: 14px !important;
}

.leaflet-pane.leaflet-shadow-pane {
  display: none;
}

.ngx-charts {
  text {
    @apply color-text-primary;
  }
}

.notification-unread {
  background-color: var(--color-btn-primary-bg);
  border-color: var(--color-header-bg);
  @apply absolute w-3 h-3 rounded-full right-1.5 top-0.5 border-2;
}

.counter {
  @apply text-xs px-2 rounded-full border bg-gray-200 text-black py-px inline-flex;
}

.table-body-cell {
  a {
    @apply text-primary underline;
  }
}

.az-dropdown-list {
  > .az-dropdown-list-item {
    @apply px-3 flex space-x-2 items-center py-2 hover:bg-gray-50 hover:cursor-pointer font-medium;
    svg {
      @apply text-gray-500;
    }
  }

  > hr {
    @apply my-1;
  }
}

.az-table-simple {
  //thead {
  //  @apply divide-y divide-gray-200;
  //  tr > {
  //    @apply divide-x divide-gray-200;
  //  }
  //}

  th {
    @apply px-3 py-1 bg-gray-50 text-xs font-medium text-gray-500 tracking-wider;
  }

  th:last-of-type {
    @apply rounded-tr-md;
  }

  th:first-of-type {
    @apply rounded-tl-md;
  }

  //tbody {
  //  @apply bg-white divide-y divide-gray-200;
  //  > tr {
  //    @apply divide-x divide-gray-200;
  //  }
  //}

  td {
    @apply px-3 py-1 whitespace-nowrap text-gray-700;
  }
}

// FORMS ERRORS

.az-form-errors {
  & > p,
  > span {
    @apply text-xs text-red-400;
  }
}

.ui-state-highlight {
  background: var(--color-primary-lighter);
}

.dts-select-container {
  @apply text-right;
}

.dts-select-container ul {
  @apply inline-flex items-center border;
  .dts-select-item {
    @apply border-t-0 border-b-0 border-l-0 border-r px-1 tracking-wide font-semibold select-none cursor-pointer text-xs  flex-shrink-0;

    &.selected {
      @apply bg-primary text-white shadow-none border-t-0 border-b-0 border-l-0;
    }

    &:last-child {
      @apply border-r-0;
    }
  }
}

.dts-select-container:not(.dts-custom) .dts-select-item.selected {
  @apply bg-primary text-white shadow-none border-t-0 border-b-0 border-l-0;
}

image-cropper .source-image {
  @apply mx-auto;
}

.form-footer {
  @apply w-full flex py-2 justify-end mt-2 px-4;
}

div {
  @apply outline-none;
}

.dropdownmenu-list {
  @apply overflow-y-auto py-2;
  & > .dropdownmenu-header {
    @apply text-xs uppercase px-5 mb-1 mt-4 font-semibold text-gray-400 tracking-tight;
  }

  & .dropdownmenu-list-item {
    @apply px-5 py-2 flex items-center w-full;
    &:hover {
      @apply cursor-pointer bg-gray-100;
    }
  }
}

.az-select-paginated-list {
  @apply overflow-y-auto py-2;
  & > .az-select-paginated-list-header {
    @apply text-xs uppercase px-5 mb-1 mt-4 font-semibold text-gray-400 tracking-tight;
  }

  & .az-select-paginated-list-item {
    @apply cursor-default select-none relative py-2 pl-3 pr-9 text-gray-900;
    &:hover {
      @apply cursor-pointer bg-primary text-white;
    }
  }
}


.heading-2 {
  @apply text-2xl font-bold leading-7 text-gray-900;
  //  sm:text-3xl sm:leading-9 sm:truncate;
}

a.button,
a.dropdownmenu-list-item,
a.sidebar-sublist-item,
a.sidebar-link-content {
  color: currentColor;
  @apply no-underline;
}

.header-item {
  @apply h-10 flex items-center justify-center border-b-4 border-transparent no-underline px-1 mt-1;
}

.dark .header-item-link {
  color: var(--color-text-primary) !important;
}

.header-item-link {
  @apply font-medium h-auto hover:text-opacity-70 inline-flex items-center space-x-1 outline-none;

  &:hover a {
    color: currentColor;
  }

  &.active {
    @apply text-primary;
  }
}

.chart-section {
  @apply border rounded bg-white shadow overflow-hidden;

  .chart-header {
    @apply border-b bg-white flex items-center justify-end p-1 h-10;

    > span {
      @apply text-base text-black;
    }
  }
}

:host ::ng-deep .ui-table .ui-table-thead > tr > th {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

@media screen and (max-width: 64em) {
  :host ::ng-deep .ui-table .ui-table-thead > tr > th {
    top: 100px;
  }
}

.app-scroll-page {
  display: flex;
  flex-direction: column;
  z-index: 1;
  flex-grow: 1;
  position: relative;
  overflow: auto;
  margin-right: 0px;
  margin-bottom: 0px;
}

.data-list {
  @apply border w-full;
  .data-list-item {
    @apply border-b flex w-full items-center bg-white;
    &:last-child {
      @apply border-b-0;
    }

    .data-list-item-selection {
      @apply ml-2 mr-0;
    }
  }
}

.preview-animation {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: 2s loading-placeholder ease-in-out infinite;
  }
}

@keyframes loading-placeholder {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

table.az-table {
  @apply rounded table-auto border-b-2 border-gray-200;
  // HEAD
  & > thead {
    @apply w-full border-b-2 border-gray-200 bg-white;
    & > tr {
      @apply bg-white border-b w-full;

      & th {
        @apply bg-white font-bold text-gray-700 text-xs tracking-wide bg-white py-px px-2 truncate;
      }
    }
  }

  // BODY
  & > tbody {
    @apply w-full;
    & > tr {
      @apply w-full;
      & > td {
        @apply bg-white px-2 py-2 text-xs z-10;
      }
    }
  }

  //  Modificaciones sobre el estilo original
  &.data-table-striped {
    & > tbody > tr:nth-child(even) {
      @apply bg-gray-100;
    }
  }
}

input.input-checkbox:indeterminate {
  border-color: transparent;
  background-size: 100% 100%;
  background: currentColor
    url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='2' x='4' y='7' rx='1'/%3E%3C/svg%3E")
    no-repeat center;
}

.app-page-with-sidebar {
  width: calc(100vw - 260px);
}

.app-header-white {
  @apply block border-b px-4 py-2 mb-2 sticky left-0;
}

.dropdownmenu-select-list {
  .cdk-virtual-scroll-content-wrapper {
    @apply w-full;
  }
}

.table-maxscreen {
  .ui-table-wrapper {
    max-height: calc(100vh - 18rem);
  }
}

.tag {
  @apply font-medium px-2 rounded-sm text-xs tracking-tighter py-px inline-flex items-center;
}

.feature-tag {
  @apply h-4 my-auto px-1 rounded bg-teal-500 ml-2 text-xs uppercase font-medium text-white flex justify-center items-center;
}

.ui-table tbody > tr > td {
  @apply border-r border-t;
  &:last-child {
    @apply border-r-0;
  }
}

.svgicon {
  @apply w-5 h-5;
  > svg {
    @apply w-full h-full fill-current;
  }

  &.svgicon-sm {
    @apply w-5 h-5;
  }

  &.svgicon-xs {
    @apply w-3 h-3;
  }

  &.svgicon-lg {
    @apply w-6 h-6;
  }
}

.dashboard-grid {
  @apply bg-transparent p-0;

  &.scrollVertical {
    overflow: visible;
  }
}

.dashboard-grid-item {
  background-color: var(--color-bg-sheet);
  box-shadow: 0 0 0 1px var(--color-border-light),0 -1px 0 var(--color-bg-app),var(--shadow-S);
  border-radius: var(--radius-L);
  .gridster-item-content {
    @apply p-4;
  }
}

.dashboard-grid-item-inicial {
  @apply bg-transparent shadow-none;
  .gridster-item-content {
    @apply p-4;
  }
}

// DIALOG
.ui-dialog {
  @apply max-h-full bg-white shadow-lg rounded overflow-y-auto;
}

.ui-dialog-content {
  @apply p-0;
}

.az-formsection {
  @apply mb-3;
}

.breadcrumb li {
  @apply text-lg;
}

.form-section-title {
  @apply text-lg font-semibold text-black;
}

.input-radio-group {
  @apply py-1 inline-flex items-center truncate;
  > label {
    @apply truncate;
  }

  > input {
    @apply mx-2;
  }
}

.field-group {
  > label {
    @apply font-medium text-neutral-500 mb-1 normal-case text-base block;
  }

  > span {
    @apply font-medium text-lg block;
  }
}

.field-label {
  @apply font-medium text-neutral-500 mb-1 normal-case text-base block;
}

.field-value {
  @apply font-medium text-lg block;
}

.az-navbar {
  @apply border-r bg-white text-base h-screen flex flex-col items-center justify-center p-2;
  .navbar-link {
    @apply w-full my-2 flex items-center justify-center text-neutral-800 cursor-pointer bg-transparent;
    &:hover {
      @apply text-black;
    }
  }

  .navbar-divider {
    @apply block bg-neutral-200 h-px w-full my-2;
  }

  .navbar-item {
    @apply w-full p-2 flex items-center justify-center;
  }
}

.az-navbar-dark {
  @apply bg-neutral-800 border-r border-neutral-800 w-14 h-screen text-white flex flex-col items-center justify-center py-2;
  .navbar-link {
    @apply w-full my-2 flex items-center justify-center text-neutral-300 cursor-pointer bg-transparent;
    &:hover {
      @apply text-white;
    }
  }

  .navbar-divider {
    @apply block bg-neutral-800 h-px w-full my-2;
  }

  .navbar-item {
    @apply w-full p-2 flex items-center justify-center text-neutral-100;
  }
}

.az-navbar-theme {
  @apply bg-neutral-800 border-neutral-700;
  .navbar-link {
    @apply text-neutral-100;
    &:hover {
      @apply text-white;
    }
  }

  .navbar-divider {
    @apply bg-neutral-600;
  }

  .navbar-item {
    @apply text-neutral-100;
  }
}


.layout-content {
  @apply h-screen;
}

.layout-extended-sidebar {
  height: 100%;
  position: relative;
  display: flex;
  flex: 1 1 0%;
  background: var(--color-bg-app);
  cursor: text;
}

// modificaciones estilos

.az-select {
  max-width: 100% !important;
}

.consumo-label {
  line-height: 2.3rem;
}

.az-tablify {
  @apply w-full;
  &.bordered {
    @apply border rounded border-collapse;
  }

  &.hoverable tbody > tr:hover td {
    @apply border-t border-b border-primary-light bg-primary-smoke cursor-pointer;
  }

  thead > tr > th {
    @apply font-semibold text-neutral-600 text-sm bg-transparent border-t py-2;
  }

  &.truncate-lines {
    td {
      @apply truncate;
    }

    .az-table-body-cell {
      @apply truncate;
    }
  }

  tbody > tr > td {
    @apply py-1 px-4 border-t;
  }

  td {
    @apply py-1 px-4;
    word-wrap: break-word;
    max-width: 320px;
  }

  th {
    display: table-cell;
    font-weight: normal;
    background: #fafafa;
  }
}

.az-tablify.sticky {
  thead tr:nth-child(1) th {
    position: sticky;
    top: -1px;
    z-index: 10;
  }
}

.ui-tabview-nav {
  @apply border-b-2 border-neutral-100;
}

.ui-tabview {
}

.ui-tabview-selected {
  @apply font-semibold;
}

/** ANIMATIONS **/
.slide-in-bottom {
  -moz-animation: slide-in-bottom 0.5s ease-in-out;
  -o-animation: slide-in-bottom 0.5s ease-in-out;
  -webkit-animation: slide-in-bottom 0.5s ease-in-out;
  animation: none;
}

.slide-out-bottom {
  -moz-animation: slide-in-bottom 0.5s ease-in-out;
  -webkit-animation: slide-in-bottom 0.5s ease-in-out;
  animation: slide-in-bottom 0.5s ease-in-out;
  -moz-animation-direction: reverse;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

@keyframes slide-in-bottom {
  0% {
    -moz-transform: translateY(1000px) translateX(-50%);
    -webkit-transform: translateY(1000px) translateX(-50%);
    transform: translateY(1000px) translateX(-50%);
    opacity: 0;
  }
  100% {
    -moz-transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -moz-transform: translateY(1000px) translateX(-50%);
    -webkit-transform: translateY(1000px) translateX(-50%);
    transform: translateY(1000px) translateX(-50%);
    opacity: 0;
  }
  100% {
    -moz-transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
  }
}

@-moz-keyframes slide-in-bottom {
  0% {
    -moz-transform: translateY(1000px) translateX(-50%);
    -webkit-transform: translateY(1000px) translateX(-50%);
    transform: translateY(1000px) translateX(-50%);
    opacity: 0;
  }
  100% {
    -moz-transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
  }
}

//** diag **/

// dialogs
.ui-dialog {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px 10px;
  @apply border-0 rounded-none;
  .ui-dialog-content {
  }

  .ui-dialog-titlebar {
    @apply justify-end;
  }
}

.az-modal.modal-factura {
  @apply m-0 absolute color-bg-overlay;
  .az-modal-content {
    padding: 0;
  }

  top: 7.3%;
  width: 60%;
  height: calc(100vh - 4.3rem);
  //left: 55.8%;
  @media (max-width: 640px) {
    width: 100%;
    height: 100%;
    top: 50%;
    // TODO: averiguar como animar bien en movil
  }
}

.az-modal.modal-dashboard {
  position: absolute;
  top: 6.7%;
  min-width: 42rem;
}

.az-modal.filtro-punto-suministro {
  @apply m-0 absolute;
  .az-modal-content {
    padding: 0;
  }

  height: 74%;
  width: 60%;
  @media (max-width: 640px) {
    height: calc(100vh);
    width: 100%;
  }
}

.az-modal.filtro-punto-suministro-single {
  @apply m-0 absolute;
  .az-modal-content {
    padding: 0;
  }

  height: 90%;
  width: 90%;
  @media (max-width: 640px) {
    height: calc(100vh);
    width: 100%;
  }
}

.ui-dialog-mask {
  @apply bg-black opacity-25;
}

.filter-dialog.ui-dialog {
  @apply max-h-full bg-white shadow-lg rounded overflow-hidden top-0 rounded-t-none;
  .ui-dialog-content {
    @apply h-full overflow-y-auto;
  }
}

@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
}

@keyframes slide-fwd-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
}

.slide-fwd-center {
  -webkit-animation: slide-fwd-center 0.2s ease-in-out both;
  animation: slide-fwd-center 0.2s ease-in-out both;
}

.informe-card {
  @apply flex flex-col border rounded cursor-pointer overflow-hidden;

  > img {
    @apply rounded-t w-full h-32;
  }

  #name {
    @apply px-3 pt-2 text-lg;
  }

  #description {
    @apply px-3 pb-2 text-neutral-600;
  }
}

.row {
  @apply flex flex-wrap -mx-2 items-center;
}

.form-valid {
  @apply hidden;
}

.ng-invalid ~ .form-valid {
  @apply block text-danger-500 text-2xl -mx-8 z-10;
}

.dark-text {
  h1,
  h2 {
    @apply text-white;
  }

  p,
  span {
    @apply text-white;
  }

}

.ui-tooltip {
  @apply -mx-1;
  .ui-tooltip-text {
    @apply rounded text-sm;
  }
}

// NGZorro Transfer
.ant-transfer {
  @apply flex w-full;
}

.ant-transfer-list {
  @apply flex w-1/2;
}

.ant-transfer-list-header {
  @apply w-full;
}

.ant-transfer-list-body {
  @apply w-full;
}

.ant-transfer-operation {
  @apply flex flex-col justify-center m-0 px-2;
  .ant-btn {
    line-height: 0.75;
  }
}

.ant-empty-image {
  @apply flex justify-center;
}

.CodeMirror {
  @apply border rounded;
}

.PARSER_HEADER .CodeMirror-code div:first-child {
  @apply bg-neutral-200 font-bold;
  .CodeMirror-linenumber {
    @apply bg-transparent font-normal;
  }
}

.CodeMirror-line {
  @apply text-left;
}

.CodeMirror-gutters {
  @apply bg-neutral-100 border-neutral-300;
}

@tailwind utilities;
@tailwind components;

// NOTIFIER
.az-notifier {
  @apply fixed w-full max-w-sm max-h-screen overflow-hidden mb-4;
}

.az-notifier .az-notification {
  @apply relative overflow-hidden block mb-2 bg-white rounded shadow-sm border transition-all duration-300;
  &:hover {
    @apply shadow;
  }

  .nofitication-message {
    .notification-info {
      @apply block;
    }

    .notification-action {
      @apply block;
    }

    .notification-close-icon {
      @apply cursor-pointer absolute top-0 right-0 mt-2 mr-2 text-gray-600;
      &:hover {
        @apply text-primary;
      }
    }

    .notification-message-content-title {
      @apply font-semibold mb-1;
    }

    .notification-message-content-text {
      @apply text-gray-600;
    }

    .notification-action-container {
      @apply border-l w-1/3 flex-grow-0 flex-shrink-0 flex flex-col;
    }

    .notification-action-button {
      @apply w-full h-full text-gray-600 outline-none;
      &:hover {
        @apply bg-gray-100;
      }

      &:not(:first-child) {
        @apply border-t;
      }
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.az-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;

  &.az-loader-white {
    border-top-color: #ffffff;
  }

  &.az-loader-small {
    @apply border-2 border-t-2 border-gray-200 h-4 w-4;
  }

  &.az-loader-large {
    @apply border-4 border-t-4 border-gray-200 h-12 w-12;
  }
}

/* Positions */
.az-notifier-top-right {
  top: 20px;
  right: 20px;
}

.az-notifier-top-left {
  top: 20px;
  left: 20px;
}

.az-notifier-bottom-right {
  bottom: 20px;
  right: 20px;
}

.az-notifier-bottom-left {
  bottom: 20px;
  left: 20px;
}

.az-notifier-top-center {
  top: 20px;
  left: 50%;
  margin-left: -10em;
}

.az-notifier-bottom-center {
  bottom: 20px;
  left: 50%;
  margin-left: -10em;
}

.az-notifier-center {
  left: 50%;
  top: 50%;
  min-width: 20vw;
  transform: translate(-50%, -50%);
}

.ui-treetable-wrapper {
  @apply rounded;
}

.ant-badge-count {
  min-width: 14px;
  height: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 2px;
  padding-left: 2px;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
  @apply bg-accent;
}

.tab {
  padding: 3px 6px;
  border-radius: 3px 3px 0px 0px;
  color: var(--color-txt-light);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  background: var(--color-bg-light);
  margin-bottom: 1px;
  margin-right: 2px;
}

.active-tab {
  background-color: #f8f8f8 !important;
  margin-bottom: 0px;
  color: #181d1f;
  border: var(--color-border);
  border-bottom: none;
}

.grid-button-box {
  background-color: var(--color-bg-app);
  border: var(--color-border);
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.save-state-btn {
  color: inherit !important;
  border-color: inherit !important;
  background-color:  inherit !important;
}

.reset-state-btn {
  color: var(--color-txt-light) !important;
  font-weight: 400;
}

app-integraciones-list {
  az-checkbox {
    input {
      @apply mt-[0.2rem] #{!important};
    }
  }
}
